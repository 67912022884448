@import '@styles/mixins.style';

.card {
  min-height: calc(470px + 24px);
  width: 280px;
  height: 100%;

  @include breakpoint(md) {
    width: 370px;
  }

  a {
    height: 100%;
  }

  [data-component='paper'] {
    &::after {
      mask-size: cover;
    }
  }

  &__image {
    width: 100%;
    img {
      height: 180px;
      object-fit: cover;
      border-radius: var(--ra-border-radius-xl) var(--ra-border-radius-xl) 0px
        0px;

      @include breakpoint(md) {
        height: 225px;
      }
    }
  }

  &__content {
    position: relative;
    padding: var(--ra-spacing-4);
    padding-top: var(--ra-spacing-6);

    &__badge {
      position: absolute;
      top: -20px;
      left: var(--ra-spacing-5);
      background-color: var(--color-neutral-2);
      padding: var(--ra-spacing-2);
      border-radius: 99px;
    }

    &__details {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    h3 {
      @include line-clamp(2, 1.15);
    }

    &__keyfact {
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      gap: var(--ra-spacing-3);
      margin-block: var(--ra-spacing-4);

      span,
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: var(--color-body-dark) !important;
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: 0;
      gap: var(--ra-spacing-4);
      flex-grow: 1;

      @include breakpoint(md) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@import '@styles/mixins.style';

.ra-image {
  display: flex;
  width: fit-content;
  height: auto;
  max-width: 100%;
  z-index: 1;

  &--max-width {
    width: 100%;
    max-width: 100% !important;
  }

  &--polaroid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--ra-spacing-4);
    background-color: var(--color-neutral-1) !important;
    padding: var(--ra-spacing-2);
    padding-bottom: var(--ra-spacing-6);
    box-shadow: var(--ra-elevation-heavy);
    transform: rotate(3deg) !important;
    width: 373px !important;
    max-width: 85vw !important;
    height: auto;
    z-index: 1;

    img {
      transform: none;
      box-shadow: none !important;
      aspect-ratio: 4 / 3;
    }
  }

  &--polaroid-content {
    padding-bottom: var(--ra-spacing-4) !important;
  }

  @include breakpoint(md) {
    &--rotate {
      &-left {
        transform: rotate(-1.5deg);
        box-shadow: var(--ra-elevation-light) !important;
      }

      &-right {
        transform: rotate(1.5deg);
        box-shadow: var(--ra-elevation-light) !important;
      }
    }
  }

  &--elevation {
    &-light {
      box-shadow: var(--ra-elevation-light);
    }

    &-dark {
      box-shadow: var(--ra-elevation-heavy);
    }
  }

  &--cover {
    object-fit: cover;
  }
}

.torn-edge {
  position: relative;
  height: auto;

  &::after {
    position: absolute;
    content: '';
    bottom: -14px;
    left: -1px;
    width: calc(100% + 2px);
    height: 24px;
    background-color: var(--torn-edge-color);
    mask: url('/assets/icons/torn-edge.svg');
    mask-position: top left;
    mask-size: cover;
    mask-repeat: repeat-x;
    transform: scaleX(-1) rotate(180deg);
  }
}
